$(window).bind('turbolinks:load', function() { initMethods(); });

function initMethods(){

  $(".describe-one li").click(function(){
      $(".describe-one li").removeClass("selected");
      $(this).addClass("selected");
  });
  $(".describe-two li").click(function(){
      $(".describe-two li").removeClass("selected");
      $(this).addClass("selected");
  });
  $(".struggle-ul li").click(function(){
      $(".struggle-ul li").removeClass("selected");
      $(this).addClass("selected");
  });
  $(".work-ul li").click(function(){
      $(".work-ul li").removeClass("selected");
      $(this).addClass("selected");
  });
  $(".interest-ul li").click(function(){
      $(".interest-ul li").removeClass("selected");
      $(this).addClass("selected");
  });
  $(".open-more-option").click(function(){
      $(".describe-more-option").slideToggle("open-option");
    $(this).toggleClass("flip-text");
  });
  $(".otr-nav-button").click(function(){
      $(".cont-navi-list ul").toggleClass("open");
  });
  $(".sidebar-close").click(function(){
      $(".cont-navi-list ul").removeClass("open");
  });
  $(".more-pay-options a").click(function(){
      $(".more-pay").slideToggle("show");
  });

  $(document).ready(function(){
    $('.cont-testimonial-slides').slick({
    });
  });

  $( "#accordion" ).accordion({
    heightStyle: "content",
    collapsible: true
  });

  // $(document).ready(function(){
  //   AOS.init({
  //         easing: 'ease-in-out-sine'
  //   });
  // });

  $('.profile_attribute').submit(function(event) {
    var valuesToSubmit = $(this).serialize();
    // document.cookie = valuesToSubmit;
    document.cookie = `profile_attributes = ${valuesToSubmit}`
    event.preventDefault();
    window.location.href = "/users/sign_up";
  });

  $('.signin_email').keyup(function(){
    $('.user_attribute').show();
    $('.facebook_sign_in').hide();
    $('.or_option').hide();
    $('.signin_button').addClass("active-btn");
  });

  $('.signup_email').keyup(function(){
    $('.user_attribute').show();
    $('.sign_up_facebook').hide();
    $('.or_option').hide();
  });

  $('.signup_email').bind("paste", function(){
    $('.user_attribute').show();
    $('.sign_up_facebook').hide();
    $('.or_option').hide();
  });

  // $('.user_signup').click(function(){
  //   $(document).scrollTop(800)
  // });


  $(".describe-ul input[type='radio']").change(function() {
    if ($(this).is(":checked")) {
      var parent_val = $(this).parent().parent()
      var class_name = parent_val[0].classList[0]
      $("."+class_name).find("label").css("background", "" )
      $("."+class_name).find("label").removeClass("option_checked");
      $(this).parent().css("background", "linear-gradient(to right, rgba(6,182,228,1) 0%,rgba(6,190,220,1) 32%,rgba(5,203,208,1) 64%,rgba(5,206,205,1) 100%)");
    }
  });

  $(".attribute-ul input[type='checkbox']").change(function() {
    if ($(this).is(":checked")) {
      $(this).parent().css("background", "linear-gradient(to right, rgba(6,182,228,1) 0%,rgba(6,190,220,1) 32%,rgba(5,203,208,1) 64%,rgba(5,206,205,1) 100%)");
    }
    else{
      $(this).parent().removeClass("option_checked");
      $(this).parent().css("background", "white" );
      $(this).parent().css("color", "black" );
    }
  });



  $(".user_password").change(function(){
    $("#user_password_confirmation").val($(this).val());
  });


  $('#new_user').validate({
    rules: {
        "user[email]": {
            required: true,
            email: true
        },
        "user[password]": {
            required: true,
            minlength: 6
        },
        "user[first_name]": {
            required: true,
            minlength: 1
        }
    },
    onkeyup: function(element) {$(element).valid()},
    highlight: function (element) {
      $(element).addClass('error').removeClass('valid')
          .closest('.form-group').addClass('error').removeClass('valid');
    },
    unhighlight: function (element) {
      $(element).addClass('valid').removeClass('error')
        .closest('.form-group').addClass('valid').removeClass('error');
    }
  });

  $('#bt-dropin').click(function(){
    $('.pay_button').show();
  });

  $(".alert").delay(5000).slideUp(300);


}
