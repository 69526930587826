// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import $ from 'jquery';
// window.jQuery = $;
window.$ = $;

// import "bootstrap";
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// require("jquery") // Don't really need to require this...
require("jquery-ui")
// require("jquery.validate.additional-methods")


// require("custom/dropin")
require("custom/bootstrap.min.js")
require("custom/validate.js")

// require("custom/jquery.mCustomScrollbar.concat.min.js")
// require("custom/jquery.mCustomScrollbar.js")

require("custom/slick.min.js")
require("custom/custom.js")
require("custom/jquery-qrcode.js")
require("custom/social_share_button.js")
require("custom/payment.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import "bootstrap"
import "css/site.scss"
// require("css/site.scss")
