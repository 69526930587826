window.paymentOptions = function (
  client_token,
  plan_price,
  GOOGLE_PAY_MERCHANT_ID
) {
  var form = $("form");
  var button = document.getElementById("google-pay-button");
  var venmoButton = document.getElementById("venmo-button");
  var apple_button = document.getElementById("apple-pay-button-id");
  var paypalButton = document.getElementById("paypal-button");
  var paymentsClient = new google.payments.api.PaymentsClient({
    environment: "PRODUCTION",
  });

  if (!window.ApplePaySession) {
    console.error("This device does not support Apple Pay");
  }

  // if (!ApplePaySession.canMakePayments()) {
  //   console.error('This device is not capable of making Apple Pay payments');
  // }

  braintree.client.create(
    {
      authorization: client_token,
    },
    function (err, clientInstance) {
      if (err) {
        console.error(err);
        return;
      }

      /* Venmo script*/
      braintree.dataCollector.create(
        {
          client: clientInstance,
          paypal: true,
        },
        function (dataCollectorErr, dataCollectorInstance) {
          if (dataCollectorErr) {
            // Handle error in creation of data collector.
            return;
          }
          // At this point, you should access the deviceData value and provide it
          // to your server, e.g. by injecting it into your form as a hidden input.
          console.log("Got device data:", dataCollectorInstance.deviceData);
        }
      );
      braintree.venmo.create(
        {
          client: clientInstance,
          allowDesktop: true,
          // Add allowNewBrowserTab: false if your checkout page does not support
          // relaunching in a new tab when returning from the Venmo app. This can
          // be omitted otherwise.
          // allowNewBrowserTab: false
        },
        function (venmoErr, venmoInstance) {
          if (venmoErr) {
            console.error("Error creating Venmo:", venmoErr);
            return;
          }
          // Verify browser support before proceeding.
          if (!venmoInstance.isBrowserSupported()) {
            console.log("Browser does not support Venmo");
            return;
          }
          displayVenmoButton(venmoInstance);
          // Check if tokenization results already exist. This occurs when your
          // checkout page is relaunched in a new tab. This step can be omitted
          // if allowNewBrowserTab is false.
          if (venmoInstance.hasTokenizationResult()) {
            venmoInstance.tokenize(function (tokenizeErr, payload) {
              if (err) {
                handleVenmoError(tokenizeErr);
              } else {
                handleVenmoSuccess(payload);
              }
            });
            return;
          }
        }
      );
      function displayVenmoButton(venmoInstance) {
        // Assumes that venmoButton is initially display: none.
        // venmoButton.style.display = 'block';
        venmoButton.addEventListener("click", function () {
          venmoButton.disabled = true;
          venmoInstance.tokenize(function (tokenizeErr, payload) {
            venmoButton.removeAttribute("disabled");
            if (tokenizeErr) {
              handleVenmoError(tokenizeErr);
            } else {
              handleVenmoSuccess(payload);
            }
          });
        });
      }
      function handleVenmoError(err) {
        if (err.code === "VENMO_CANCELED") {
          console.log("App is not available or user aborted payment flow");
        } else if (err.code === "VENMO_APP_CANCELED") {
          console.log("User canceled payment flow");
        } else {
          console.error("An error occurred:", err.message);
        }
      }

      function handleVenmoSuccess(payload) {
        // Send the payment method nonce to your server, e.g. by injecting
        // it into your form as a hidden input.
        document.querySelector("#nonce").value = payload.nonce;
        paymentScript();
        console.log("Got a payment method nonce:", payload.nonce);
        // Display the Venmo username in your checkout UI.
        console.log("Venmo user:", payload.details.username);
      }

      /* Apple pay script */
      braintree.applePay.create(
        {
          client: clientInstance,
        },
        function (applePayErr, applePayInstance) {
          if (applePayErr) {
            console.error("Error creating applePayInstance:", applePayErr);
            return;
          }
          // Set up your Apple Pay button here
          apple_button.addEventListener("click", function (event) {
            var paymentRequest = applePayInstance.createPaymentRequest({
              countryCode: "US",
              currencyCode: "USD",
              supportedNetworks: ["visa", "masterCard", "amex"],
              merchantCapabilities: ["supports3DS"],
              total: {
                label: "My Store",
                amount: plan_price,
              },
              // We recommend collecting billing address information, at minimum
              // billing postal code, and passing that billing postal code with
              // all Apple Pay transactions as a best practice.
              requiredBillingContactFields: ["postalAddress"],
            });
            console.log(paymentRequest.countryCode);
            console.log(paymentRequest.currencyCode);
            console.log(paymentRequest.merchantCapabilities);
            console.log(paymentRequest.supportedNetworks);
            var session = new ApplePaySession(1, paymentRequest);
            console.log("error:", session);
            session.onvalidatemerchant = function (event) {
              console.log("valid:", event);
              applePayInstance.performValidation(
                {
                  validationURL: event.validationURL,
                  displayName: "My Store",
                },
                function (err, merchantSession) {
                  if (err) {
                    console.log("merchanterror:", err);
                    // You should show an error to the user, e.g. 'Apple Pay failed to load.'
                    return;
                  }
                  session.completeMerchantValidation(merchantSession);
                }
              );
            };
            console.log("xyx:");
            session.onpaymentauthorized = function (event) {
              console.log(
                "Your shipping address is:",
                event.payment.shippingContact
              );
              applePayInstance.tokenize(
                {
                  token: event.payment.token,
                },
                function (tokenizeErr, payload) {
                  if (tokenizeErr) {
                    console.error("Error tokenizing Apple Pay:", tokenizeErr);
                    session.completePayment(ApplePaySession.STATUS_FAILURE);
                    return;
                  }
                  // Send payload.nonce to your server.
                  document.querySelector("#nonce").value = payload.nonce;
                  paymentScript();
                  console.log("nonce:", payload.nonce);
                  // If requested, address information is accessible in event.payment
                  // and may also be sent to your server.
                  console.log(
                    "billingPostalCode:",
                    event.payment.billingContact.postalCode
                  );
                  // After you have transacted with the payload.nonce,
                  // call `completePayment` to dismiss the Apple Pay sheet.
                  session.completePayment(ApplePaySession.STATUS_SUCCESS);
                }
              );
            };
            session.begin();
          });
        }
      );

      /* Google Pay script */
      braintree.googlePayment.create(
        {
          client: clientInstance,
          googlePayVersion: 2,
          googleMerchantId: GOOGLE_PAY_MERCHANT_ID, // Optional in sandbox; if set in sandbox, this value must be a valid production Google Merchant ID
        },
        function (googlePaymentErr, googlePaymentInstance) {
          paymentsClient
            .isReadyToPay({
              // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest
              apiVersion: 2,
              apiVersionMinor: 0,
              allowedPaymentMethods:
                googlePaymentInstance.createPaymentDataRequest()
                  .allowedPaymentMethods,
              existingPaymentMethodRequired: true, // Optional
            })
            .then(function (response) {
              if (response.result) {
                button.addEventListener("click", function (event) {
                  event.preventDefault();
                  var paymentDataRequest =
                    googlePaymentInstance.createPaymentDataRequest({
                      transactionInfo: {
                        currencyCode: "USD",
                        totalPriceStatus: "FINAL",
                        totalPrice: plan_price, // Your amount
                      },
                    });
                  // We recommend collecting billing address information, at minimum
                  // billing postal code, and passing that billing postal code with all
                  // Google Pay card transactions as a best practice.
                  // See all available options at https://developers.google.com/pay/api/web/reference/object
                  var cardPaymentMethod =
                    paymentDataRequest.allowedPaymentMethods[0];
                  cardPaymentMethod.parameters.billingAddressRequired = true;
                  cardPaymentMethod.parameters.billingAddressParameters = {
                    format: "FULL",
                    phoneNumberRequired: false,
                  };
                  paymentsClient
                    .loadPaymentData(paymentDataRequest)
                    .then(function (paymentData) {
                      return googlePaymentInstance.parseResponse(
                        paymentData,
                        function (err, result) {
                          if (err) {
                            // Handle parsing error
                          }
                          document.querySelector("#nonce").value = result.nonce;
                          // Send result.nonce to your server
                          // result.type may be either "AndroidPayCard" or "PayPalAccount", and
                          // paymentData will contain the billingAddress for card payments
                          paymentScript();
                        }
                      );
                    })
                    .catch(function (err) {
                      // Handle errors
                    });
                });
              }
            })
            .catch(function (err) {
              // Handle errors
            });
        }
      );
      braintree.paypal.create(
        {
          client: clientInstance,
        },
        function (paypalErr, paypalInstance) {
          if (paypalErr) {
            console.error("Error creating PayPal:", paypalErr);
            return;
          }

          paypalButton.removeAttribute("disabled");

          paypalButton.addEventListener("click", function () {

            paypalInstance.tokenize(
              {
                flow: "vault",
              },
              function (tokenizeErr, payload) {
                if (tokenizeErr) {
                  if (tokenizeErr.type !== "CUSTOMER") {
                    console.error("Error tokenizing:", tokenizeErr);
                  }
                  return;
                }

                // Tokenization succeeded
                paypalButton.setAttribute("disabled", true);
                document.querySelector("#nonce").value = payload.nonce;
                paymentScript();
              }
            );
          });
        }
      );
      // TODO: This is the new code, the previous code for just paypal is outdated. However, using a custom button isn't well documented.
      // braintree.paypalCheckout.create(
      //   {
      //     client: clientInstance,
      //   },
      //   function (paypalCheckoutErr, paypalCheckoutInstance) {
      //     if (paypalCheckoutErr) {
      //       console.error(
      //         "Error creating paypalCheckoutInstance:",
      //         paypalCheckoutErr
      //       );
      //       return;
      //     }

      //     paypalCheckoutInstance.loadPayPalSDK(
      //       {
      //         currency: "USD",
      //         vault: true,
      //       },
      //       function () {
      //         paypal
      //           .Buttons({
      //             style: {
      //               shape: "pill",
      //               height: 55,
      //             },

      //             fundingSource: paypal.FUNDING.PAYPAL,

      //             createBillingAgreement: function () {
      //               return paypalCheckoutInstance.createPayment({
      //                 flow: "vault",
      //                 amount: plan_price, // Required
      //                 currency: "USD",
      //               });
      //             },

      //             onApprove: function (data, actions) {
      //               return paypalCheckoutInstance.tokenizePayment(
      //                 data,
      //                 function (err, payload) {
      //                   // Submit `payload.nonce` to your server

      //                   if (err) {
      //                     console.log(err);
      //                   }

      //                   document.querySelector("#nonce").value = payload.nonce;
      //                   paymentScript();
      //                   console.log(
      //                     "Got a payment method nonce:",
      //                     payload.nonce
      //                   );
      //                   // Display the Venmo username in your checkout UI.
      //                   console.log("Venmo user:", payload.details.username);
      //                 }
      //               );
      //             },

      //             onCancel: function (data) {
      //               console.log(
      //                 "PayPal payment cancelled",
      //                 JSON.stringify(data, 0, 2)
      //               );
      //             },

      //             onError: function (err) {
      //               console.error("PayPal error", err);
      //             },
      //           })
      //           .render("#paypal-button")
      //           .then(function () {
      //             // The PayPal button will be rendered in an html element with the ID
      //             // `paypal-button`. This function will be called when the PayPal button
      //             // is set up and ready to be used
      //             console.log("paypal button available");
      //           });
      //       }
      //     );
      //   }
      // );

      /* Card Pay script */
      braintree.hostedFields.create(
        {
          client: clientInstance,
          styles: {
            input: {
              // change input styles to match
              // bootstrap styles
              "font-size": "16px",
              color: "#495057",
              "line-height": "28px",
            },
            "::placeholder": {
              color: "#b1b2b3",
              opacity: 1,
            },
            "::-moz-placeholder": {
              color: "#b1b2b3",
              opacity: 1,
            },
            "@media only screen and (max-width: 770px)": {
              input: {
                "font-size": "15px",
              },
            },
          },
          fields: {
            number: {
              selector: "#cc-number",
              placeholder: "Credit card number",
            },
            cvv: {
              selector: "#cc-cvv",
              placeholder: "Security Code             CVV",
            },
            expirationDate: {
              selector: "#cc-expiration",
              placeholder: "Expiration                MM/YY",
            },
          },
        },
        function (err, hostedFieldsInstance) {
          if (err) {
            console.error(err);
            return;
          }
          function createInputChangeEventListener(element) {
            return function () {
              validateInput(element);
            };
          }
          function setValidityClasses(element, validity) {
            if (validity) {
              element.removeClass("is-invalid");
              element.addClass("is-valid");
            } else {
              element.addClass("is-invalid");
              element.removeClass("is-valid");
            }
          }
          function validateInput(element) {
            // very basic validation, if the
            // fields are empty, mark them
            // as invalid, if not, mark them
            // as valid
            if (!element.val().trim()) {
              setValidityClasses(element, false);
              return false;
            }
            setValidityClasses(element, true);
            return true;
          }
          function validateEmail() {
            var baseValidity = validateInput(email);
            if (!baseValidity) {
              return false;
            }
            if (email.val().indexOf("@") === -1) {
              setValidityClasses(email, false);
              return false;
            }
            setValidityClasses(email, true);
            return true;
          }
          var ccName = $("#cc-name");
          var email = $("#email");
          ccName.on("change", function () {
            validateInput(ccName);
          });
          email.on("change", validateEmail);
          hostedFieldsInstance.on("validityChange", function (event) {
            var field = event.fields[event.emittedBy];
            // Remove any previously applied error or warning classes
            $(field.container).removeClass("is-valid");
            $(field.container).removeClass("is-invalid");
            if (field.isValid) {
              $(field.container).addClass("is-valid");
            } else if (field.isPotentiallyValid) {
              // skip adding classes if the field is
              // not valid, but is potentially valid
            } else {
              $(field.container).addClass("is-invalid");
            }
          });
          hostedFieldsInstance.on("cardTypeChange", function (event) {
            var cardBrand = $("#card-brand");
            var cvvLabel = $('[for="cc-cvv"]');
            $(".content-hide").removeClass("hidden");
            $(".content-show").hide();
            if (event.cards.length === 1) {
              var card = event.cards[0];
              // change pay button to specify the type of card
              // being used
              cardBrand.text(card.niceType);
              // update the security code label
              cvvLabel.text(card.code.name);
            } else {
              // reset to defaults
              cardBrand.text("Card");
              cvvLabel.text("CVV");
            }
          });
          form.submit(function (event) {
            event.preventDefault();
            var formIsInvalid = false;
            var state = hostedFieldsInstance.getState();
            // perform validations on the non-Hosted Fields
            // inputs
            if (!validateEmail() || !validateInput($("#cc-name"))) {
              formIsInvalid = true;
            }
            // Loop through the Hosted Fields and check
            // for validity, apply the is-invalid class
            // to the field container if invalid
            Object.keys(state.fields).forEach(function (field) {
              if (!state.fields[field].isValid) {
                $(state.fields[field].container).addClass("is-invalid");
                formIsInvalid = true;
              }
            });
            if (formIsInvalid) {
              // skip tokenization request if any fields are invalid
              return;
            }
            hostedFieldsInstance.tokenize(
              {
                // include the cardholderName in the tokenization
                // request
                cardholderName: $("#cc-name").val(),
              },
              function (err, payload) {
                if (err) {
                  console.error(err);
                  return;
                }
                // This is where you would submit payload.nonce to your server
                // $('.toast').toast('show');

                // you can either send the form values with the payment
                // method nonce via an ajax request to your server,
                // or add the payment method nonce to a hidden inpiut
                // on your form and submit the form programatically
                document.querySelector("#nonce").value = payload.nonce;
                document.querySelector("#p-code").value =
                  document.getElementById("cc-code").value;
                paymentScript();
              }
            );
          });
        }
      );
    }
  );
  function paymentScript() {
    $.ajax({
      type: "post",
      url: "/checkout",
      data: $("#payment-form").serialize(),
      success: function (data_resp) {
        var msg = data_resp["notice"]
          ? data_resp["notice"]
          : "Payment completed successfully!";
        alert(msg);
      },
    });
  }
};
